import React from "react";

import { Col, Container, Row } from "reactstrap";

import Main from "../components/Main";
import Settings from "../components/Settings";

import loginbg from "../assets/img/illustrations/chart.png"

const Auth = ({ children }) => (
  <React.Fragment>
    <Main className="d-flex w-100 ">
      <Container className="d-flex flex-column">
        <Row className="h-100">
        <Col sm="6" md="6" lg="6" className="mx-auto d-table h-100 ">
            <div className="d-table-cell align-middle"><img src={loginbg} width="500px"></img></div>
          </Col>
          <Col sm="6" md="6" lg="6" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle ">{children}</div>
          </Col>
        </Row>
      </Container>
    </Main>
  </React.Fragment>
);

export default Auth;
