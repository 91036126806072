import React from "react";
import { 
  BrowserRouter as Router, 
  Route, 
  Switch,
  Redirect } from "react-router-dom";
import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  admindashboard as admindashboardRoutes,
  page as pageRoutes,
  login,
  register,
  otp
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";

import { hasRole, isAllowed, isAuthenticated } from "../components/Authentication";

// localStorage.removeItem('session');

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, roles, rights, needAuth, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, roles, rights, needAuth, component: Component }, index) => (
        <Route
          key={index}
          path={path}          
          exact          
          render={props => 
            {
              console.log('children routes');

              var session = localStorage.getItem('session');
              console.log('session: ', session);

              var user = JSON.parse(session);

              console.log('user: ', user);
              console.log('roles: ', roles);
              console.log('rights: ', rights);
              console.log('needAuth: ', needAuth); 
              console.log('isAuthenticated: ', isAuthenticated(user));

              if(needAuth)
              {
                console.log('needAuth true');
                if(isAuthenticated(user))
                {
                  console.log('isAuthenticated true');
                  if(hasRole(user, roles) && isAllowed(user, rights))
                  {
                    console.log('hasRole true isAllowed true');
                    return (<Layout>
                              <Component {...props} />
                            </Layout>)
                  }
                  else
                  {
                    console.log('hasRole false || isAllowed false');
                    return (<Redirect to='/' />)
                  }
                }                
                else
                {
                  console.log('isAuthenticated false');
                  return (<Redirect to='/login' />)
                }
              }                
              else 
              {                
                console.log('isAuthenticated false');
                return (<Layout>
                          <Component {...props} />
                        </Layout>)
              }                               
            }
          }
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => 
          {            
            console.log('no children routes');

            var session = localStorage.getItem('session');
            console.log('session: ', session);

            var user = JSON.parse(session);

            console.log('user: ', user);
            console.log('roles: ', roles);
            console.log('rights: ', rights);
            console.log('needAuth: ', needAuth);

            if(needAuth)
            {
              if(isAuthenticated(user))
              {
                if(hasRole(user, roles) && isAllowed(user, rights))
                {
                  console.log('hasRole true isAllowed true');
                  return (<Layout>
                            <Component {...props} />
                          </Layout>)
                }
                else
                {
                  console.log('hasRole false isAllowed false');
                  return (<Redirect to='/' />)
                }
              }                
              else
              {
                console.log('isAuthenticated false');
                return (<Redirect to='/login' />)
              }
            }              
            else 
            {
              if(path == '/login')
              {
                console.log('path login');
                console.log('user: ', user);
                if(isAuthenticated(user))
                {
                  console.log('user isAuthenticated');
                  return (<Redirect to='/dashboard/default' />)
                }
                else
                {
                  console.log('isAuthenticated false');
                  return (<Layout>
                            <Component {...props} />
                          </Layout>)
                }
              }

              return (<Layout>
                        <Component {...props} />
                      </Layout>)
            }                           
          }
        }
      />
    )
  );

const Routes = () => {

  console.log('Routes');  
  var session = localStorage.getItem('session');
  console.log('session: ', session);

  var user = JSON.parse(session);

  return (
    <Router>
      <ScrollToTop>
        <Switch>        
          {childRoutes(DashboardLayout, dashboardRoutes)}
          {childRoutes(DashboardLayout, admindashboardRoutes)}
          {childRoutes(AuthLayout, pageRoutes)}
          {childRoutes(AuthLayout, login)} 
          {childRoutes(AuthLayout, register)}        
          {childRoutes(AuthLayout, otp)}        

          <Route
            path='/'
            render={() => 
              {
                console.log('isAuthenticated: ', isAuthenticated(user));
                return isAuthenticated(user) ? (<Redirect to='/dashboard/default' />) : (<Redirect to='/login' />);
              }
            }
          />

          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  )
};

export default Routes;
