import React from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Settings from "../components/Settings";

const Dashboard = ({ children }) => 
{
  console.log('Dashboard');

  var session = JSON.parse(localStorage.getItem('session'));
  console.log('session: ', session);

  var user = session.user;

  console.log('emai_verified_at: ', user.email_verified_at);

  return (
    <React.Fragment>
      <Wrapper>
        { user.email_verified_at != null ? (<Sidebar />) : null }
        <Main>
          <Navbar />
          <Content>{children}</Content>
          <Footer />
        </Main>
      </Wrapper>
    </React.Fragment>
  );
}

export default Dashboard;
