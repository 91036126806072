import React, { useState } from "react";

// export const user = {
//   name: 'Jackator',  
//   roles: ['admin', 'user'],
//   rights: ['V_dashboard', 'V_profile', 'V_uploadeddocuments', 'V_questionnaire', 'V_openaccount', 'V_accountoverview', 'V_depositfunds', 'V_withdrawfunds', 'V_transferfunds',
// 		  'V_transactionhistory', 'V_paymentdetails', 'V_requestib', 'V_settings', 'V_ibdashboard', 'V_ibtransaction', 'V_commissionbreakdown', 'V_ibwithdraw', 'V_transfertradingaccount',
// 		  'V_performancedashboard', 'V_partnerlinks', 'V_othernetworkcommission', 'V_partnerbanners' ]
// };

// export var user = session != null || session != undefined ? JSON.parse(session) : {roles:[], rights:[]};

// export const user = {
//   name: 'Jackator',  
//   roles: [],
//   rights: []
// };

// export const isAuthenticated = user => !!user;
// export const isAuthenticated = user => false;
export const isAuthenticated = user => 
{
	console.log('isAuthenticated');
	console.log('user: ', user);
	if(user != null)
		return true;
	else
		return false;
}

export const isAllowed = (user, rights) =>
  rights.some(right => user.rights.includes(right));

export const hasRole = (user, roles) =>
  roles.some(role => user.roles.includes(role));