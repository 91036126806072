import React from "react";
import classnames from "classnames";
import InputMask from "react-input-mask";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

const empstatus = [
  { value: "1", label: "Employed" },
  { value: "2", label: "Self Employed" },
  { value: "3", label: "Retired" },
  { value: "4", label: "Student" },
  { value: "5", label: "Unemployed" }
];

const Blank = () => (
  <Container fluid className="p-0">    
    <h1 className="h3 mb-3">Confirmation</h1>
    <Row>
      <Col>
        <Card>
          <CardBody>
            <p>Congratulations! Your accout had been verified. Make sure you click 'Check Verify' button in the dashboard page. You may close this tab now.</p>              
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Blank;
