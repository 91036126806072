import React from 'react';

const ApiConfig = () => 
{
	console.log('ApiConfig');
	console.log('hostname: ', window.location.hostname);

	var host = window.location.hostname;
	var api;

	switch(host)
	{
		case 'localhost':
			api = {
				'backendURL': 'http://3.1.239.108/explorer/backend/public/api/v1'
		  	};
		  	break;

		case 'exp.picelcom.my':
		 	api = {
		  		'backendURL': 'http://3.1.239.108/explorer/backend/public/api/v1'
		  	};
		  	break;
	}  	

  	return api;
}

export default ApiConfig;