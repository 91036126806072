import React, { useState, useEffect, useParams } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, Redirect } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Cookies from 'universal-cookie';

import { country } from "./country";


import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCircleNotch, faArrowLeft, faHourglass, faClock, faCheck, faMousePointer, faPencilAlt
} from "@fortawesome/free-solid-svg-icons";

import FormController from '../../components/FormController';

import ApiConfig from '../../components/ApiConfig';

var apiUrl = ApiConfig().backendURL;

const ClientTypeOptions = () =>
{
  const typesArr = [
    { value: "I", label: "Individual CLient" },
    { value: "C", label: "Corporate Client" }
  ];

  var optionsArr = [];

  for(let i=0; i<typesArr.length; i++)
  {
    optionsArr.push(<option key={typesArr[i].value} value={typesArr[i].value}> {typesArr[i].label} </option>)
  }

  return optionsArr;
}

class Birthdate extends React.Component {
  state = {
    startDate: new Date()
  };

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };

  render() {
    return (
      <FormGroup>
        <Label>Birth Date</Label><br />
        <DatePicker
          className="form-control"
          selected={this.state.startDate}
          name="birthDt"
          onChange={this.handleChange}
        />
      </FormGroup>
    );
  }
}

const SignUp = ({ match }) => 
{
  let code = match.params.code ?? '';
  const[signUpSuccess, setSignUpSuccess] = useState(false);  
  const[titleApiCalled, setTitleApiCalled] = useState({called:false, data:[]});

  useEffect(() => {   
    const cookies = new Cookies();
    // uncomment line ni untuk reset cookies
    //cookies.remove('code'); 
    if(cookies.get('code') == null) {
      setInputs(inputs => ({...inputs, code_ib: code}));

      var formData = new FormData();
      formData.set('code', code);

      fetch(apiUrl + '/introducer-link/click/', {
        method: 'POST',      
        body: formData,
      }).then(res => res.json())
      .then(
        (result) => {
  
        },
        (error) => {
          console.log(JSON.stringify(error));
          alert(error);
        }
      )
      cookies.set('code',code, {maxAge: 300});
      //cookies expired in 5 minutes
    } else {
      code = cookies.get('code');
      setInputs(inputs => ({...inputs, code_ib: code}));
      
    }
  
  },[code]);

  const titleApi = () =>
  {
    console.log('titleApi');

    fetch(apiUrl + '/salutation/all', {
        method: 'GET'      
      })
        .then(res => res.json())
        .then(
          (result) => {
            console.log('result titleApi: ', result);

            if(result.status == 'success')
            {
              var data = result.data.salutations;
              console.log('data: ', data);

              var titlesArr = [];              

              for(let i=0; i<data.length; i++)
              {
                titlesArr.push({value: data[i].id, label: data[i].name});
              }

              setTitleApiCalled({called:true, data: titlesArr});
              // return result.data.salutation;
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            alert(JSON.stringify(error));
          }
        )
  }

  const registerApi = () =>
  {
    console.log('loginApi');
    console.log('inputs: ', inputs);

    if(inputs.password != inputs.c_password)
    {
      alert('Password not matched.');
      return;
    }

    var formData = new FormData();

    formData.set('name', inputs.name_first + ' ' + inputs.name_middle + ' ' + inputs.name_last);
    formData.set('email', inputs.email);
    formData.set('password', inputs.password);
    formData.set('c_password', inputs.c_password);
    formData.set('cd_type', inputs.cd_type);
    formData.set('id_salutation', inputs.id_salutation);
    formData.set('name_first', inputs.name_first);
    formData.set('name_middle', inputs.name_middle);
    formData.set('name_last', inputs.name_last);
    formData.set('dt_birth', inputs.dt_birth);
    formData.set('id_country', inputs.id_country.value);
    formData.set('phone', inputs.phone);
    formData.set('cd_language', inputs.cd_language);
    formData.set('code_ib', inputs.code_ib);

    console.log('fd name: ', formData.get('name'));
    console.log('fd email: ', formData.get('email'));
    console.log('fd password: ', formData.get('password'));
    console.log('fd c_password: ', formData.get('c_password'));
    console.log('fd cd_type: ', formData.get('cd_type'));
    console.log('fd id_salutation: ', formData.get('id_salutation'));
    console.log('fd name_first: ', formData.get('name_first'));
    console.log('fd name_middle: ', formData.get('name_middle'));
    console.log('fd name_last: ', formData.get('name_last'));
    console.log('fd dt_birth: ', formData.get('dt_birth'));
    console.log('fd id_country: ', formData.get('id_country'));
    console.log('fd phone: ', formData.get('phone'));
    console.log('fd cd_language: ', formData.get('cd_language'));
    console.log('fd code_ib: ', formData.get('code_ib'));

    fetch(apiUrl + '/user/register', {
      method: 'POST',      
      body: formData
    })
      .then(res => res.json())
      .then(
        (result) => {
          // alert(JSON.stringify(result));
          if(result.status == 'success')
          {
            var userSession = {
              user: result.data.user,
              roles: result.data.role,
              rights: result.data.rights,
              token: result.data.token
            };

            localStorage.setItem('session', JSON.stringify(userSession));

            // alert('session: '+localStorage.getItem('session'));
            // return (<Redirect to='/dashboard/default' />)
            setSignUpSuccess(true);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert(JSON.stringify(error));
        }
      )
  }

  const TitleOptions = () =>
  {
    console.log('TitleOptions');

    var optionsArr = [];
    var titlesArr = titleApiCalled.data;

    if(titlesArr.length > 0)
    {
      for(let i=0; i<titlesArr.length; i++)
      {
        optionsArr.push(<option key={titlesArr[i].value} value={titlesArr[i].value}> {titlesArr[i].label} </option>)
      }

      return optionsArr;
    }
    else
    {
      return null;
    }
  }

  const handleCountryInputChange = (event) =>
  {
    console.log('handleCountryInputChange');
    console.log('event: ', event);  

    setInputs(inputs => ({...inputs, id_country:event}));
    console.log('inputs: ', inputs);
  }

  const LanguageOptions = () =>
  {
    console.log('LanguageOptions');

    const languageArr = [
      { value: "EN", label: "English" },
      { value: "MY", label: "Malay" }
    ];

    var optionsArr = [];

    for(let i=0; i<languageArr.length; i++)
    {
      optionsArr.push(<option key={languageArr[i].value} value={languageArr[i].value}> {languageArr[i].label} </option>)
    }

    return optionsArr;
  }  

  const today = new Date();

  console.log('titleApiCalled called: ', titleApiCalled.called);

  if(!titleApiCalled.called)
    titleApi();

  console.log('titleApiCalled data: ', titleApiCalled.data);

  const {inputs, setInputs, handleInputChange, handleSubmit} = FormController(registerApi);
  console.log('inputs: ', inputs);

  if(signUpSuccess)
    return (<Redirect to="/dashboard" />)
  else
    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h1 className="h2">Get started</h1>
          <p className="lead">
            Fill in the registration details below.
          </p>
        </div>
        <Row>
          <Col lg="6" className="mb-4">
          <a href="/login" className="h3 text-primary"><FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> Return to Login</a>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <Form>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    onChange={handleInputChange}
                    value={inputs.email || ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    onChange={handleInputChange}
                    value={inputs.password || ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Confirmation Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="c_password"
                    placeholder="Re-enter your password"
                    onChange={handleInputChange}
                    value={inputs.c_password || ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>I want to register as</Label>                
                  <Input
                    className="react-select-container"                  
                    type="select"
                    id="cd_type"
                    name="cd_type"
                    className="mb-3"                  
                    onChange={handleInputChange}
                    value={inputs.cd_type || ''}
                  >
                    <option value="">Select client type</option>
                    <ClientTypeOptions />
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    className="react-select-container"                  
                    type="select"
                    id="id_salutation"
                    name="id_salutation"
                    className="mb-3"                  
                    onChange={handleInputChange}
                    value={inputs.id_salutation || ''}
                  >
                    <option value="">Select title</option>
                    <TitleOptions />
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    bsSize="lg"
                    type="text"                  
                    placeholder="Enter your first name"
                    name="name_first"
                    onChange={handleInputChange}
                    value={inputs.name_first || ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Middle Name</Label>
                  <Input
                    bsSize="lg"
                    type="text"                  
                    placeholder="Enter your middle name"
                    name="name_middle"
                    onChange={handleInputChange}
                    value={inputs.name_middle || ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    bsSize="lg"
                    type="text"                  
                    placeholder="Enter your last name"
                    name="name_last"
                    onChange={handleInputChange}
                    value={inputs.name_last || ''}
                  />
                </FormGroup>
                {/*<Birthdate />*/}
                <FormGroup>
                  <Label>Birth Date</Label><br />
                  <Input
                    className="form-control" 
                    type="date"   
                    placeholder={today}
                    name="dt_birth"                  
                    onChange={handleInputChange}
                    value={inputs.dt_birth || ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Country</Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    type="select"
                    options={country}
                    isSearchable
                    name="id_country"
                    onChange={handleCountryInputChange}
                    value={inputs.id_country || ''}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Phone Number</Label>
                  <Input
                    bsSize="lg"
                    type="number"
                    name="phone"
                    placeholder="Enter your phone number"
                    onChange={handleInputChange}
                    value={inputs.phone || ''}
                  />
                </FormGroup>              
                <FormGroup>
                  <Label>Preferred Language</Label>
                  <Input
                    className="react-select-container"                  
                    type="select"
                    id="cd_language"
                    name="cd_language"
                    className="mb-3"                  
                    onChange={handleInputChange}
                    value={inputs.cd_language || ''}
                  >
                    <option value="">Select language</option>
                    <LanguageOptions />
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>IB Code</Label>
                  <Input
                    bsSize="lg"
                    disabled="disabled"
                    name="code_ib"
                    placeholder="Enter IB Code"
                    value={inputs.code_ib || ''}
                  />
                </FormGroup>
                <FormGroup check className="mb-2">
                  <Label check>
                    <Input 
                      type="checkbox"
                      name="isAccept"
                      onChange={handleInputChange}
                      value={inputs.isAccept || ''}
                    /> I accept the <a href="#">RISK DISCLOSURE POLICY, ANTI SPAM POLICY, PRIVACY POLICY, CLIENT AGREEMENT, ORDER EXECUTION POLICY, REFUND POLICY</a>
                  </Label>
                </FormGroup>
                <div className="text-center mt-3">
                  <Link to="/auth/confirmation">

                    { 
                      inputs.isAccept == true ? 
                        <Button 
                          color="primary" 
                          size="lg"  
                          onClick={handleSubmit}                        
                          >
                          Sign Up
                        </Button> :
                          <Button 
                            color="primary" 
                            size="lg"
                            disabled
                            >
                            Sign Up
                          </Button>                         
                    }
                    
                  </Link>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
}

export default SignUp;
