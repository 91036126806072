import async from "../components/Async";

import {
  BookOpen as BookOpenIcon,
  CheckSquare as CheckSquareIcon,
  Grid as GridIcon,
  Heart as HeartIcon,
  Layout as LayoutIcon,
  List as ListIcon,
  MapPin as MapPinIcon,
  Monitor as MonitorIcon,
  PieChart as PieChartIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
  Calendar as CalendarIcon,
  DollarSign,
  Settings as settingsicon,
  Briefcase,
  Activity,
  BarChart,
  Star,
  Image,
  GitMerge,
  Download,
  File,
  Send,
  GitBranch,
  HelpCircle,
} from "react-feather";

// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Confirmation from "../pages/auth/Confirmation";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import ExamLogin from "../pages/auth/ExamLogin";


// Layouts
import Boxed from "../pages/layouts/Boxed";
import SidebarCollapsed from "../pages/layouts/SidebarCollapsed";
import SidebarSticky from "../pages/layouts/SidebarSticky";
import ThemeClassic from "../pages/layouts/ThemeClassic";
import ThemeCorporate from "../pages/layouts/ThemeCorporate";
import ThemeModern from "../pages/layouts/ThemeModern";

// Misc
import Blank from "../pages/misc/Blank";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
import Notifications from "../pages/ui-elements/Notifications";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";

// Pages
//import Profile from "../pages/pages/Profile";
import Settings from "../pages/pages/Settings";
import Clients from "../pages/pages/Clients";
import Projects from "../pages/pages/Projects";
import Invoice from "../pages/pages/Invoice";
import Pricing from "../pages/pages/Pricing";
import Tasks from "../pages/pages/Tasks";

// Documentation
import Introduction from "../pages/docs/Introduction";
import Installation from "../pages/docs/Installation";
import Plugins from "../pages/docs/Plugins";
import Changelog from "../pages/docs/Changelog";
import questionnaire from "../pages/profile/questionnaire";

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const Ecommerce = async(() => import("../pages/dashboards/Ecommerce"));
const Crypto = async(() => import("../pages/dashboards/Crypto"));
const Social = async(() => import("../pages/dashboards/Social"));

// Forms
const Layouts = async(() => import("../pages/forms/Layouts"));
const BasicElements = async(() => import("../pages/forms/BasicElements"));
const AdvancedElements = async(() => import("../pages/forms/AdvancedElements"));
const InputGroups = async(() => import("../pages/forms/InputGroups"));
const Editors = async(() => import("../pages/forms/Editors"));
const Validation = async(() => import("../pages/forms/Validation"));
const Wizard = async(() => import("../pages/forms/Wizard"));

// Tables
const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
const AdvancedTables = async(() => import("../pages/tables/Advanced"));

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

//profile
const Profile1 = async(() => import("../pages/profile/Profile"));
const Documents = async(() => import("../pages/profile/documents"));
const Questionnaire = async(() => import("../pages/profile/questionnaire"));
const Agreements = async(() => import("../pages/profile/agreements"));


//funds
const Deposit = async(() => import("../pages/funds/Deposit"));
const Withdraw = async(() => import("../pages/funds/Withdraw"));
const Transfer = async(() => import("../pages/funds/Transfer"));
const Transaction = async(() => import("../pages/funds/Transaction"));
const PaymentDetails = async(() => import("../pages/funds/PaymentDetails"));

//settings
const SettingsMain = async(() => import("../pages/settings/settings"));

//download mt4
const Mt4Download = async(() => import("../pages/download/Download"));


//Accounts
const OpenAccount = async(() => import("../pages/account/OpenAccount"));
const AccountOverview = async(() => import("../pages/account/Account"));
const ViewAccount = async(() => import("../pages/account/ViewAccount"));
const TradingHistory = async(() => import("../pages/account/TradingHistory"));



//Request IB
const IB = async(() => import("../pages/IB/RequestIB"));
const Tree = async(() => import("../pages/IB/IBTree"));
const IBDashboard = async(() => import("../pages/IB/IBDashboard"));
const PerformanceDashboard = async(() => import("../pages/IB/PerformanceDashboard"));
const Links = async(() => import("../pages/IB/Links"));
const OtherNetworkCommission = async(() => import("../pages/IB/OtherNetworkCommission"));
const Banners = async(() => import("../pages/IB/Banners"));

//IB Dashboard
const IBTransaction = async(() => import("../pages/IB/IBTransaction"));
const Breakdown = async(() => import("../pages/IB/DetailCommissionBreakdown"));
const IBWithdraw = async(() => import("../pages/IB/Withdraw"));
const IBTransfer = async(() => import("../pages/IB/Transfer"));

//admin
const AdminDashboard = async(() => import("../pages/admin/Dashboard"));
const FinancialDashboard = async(() => import("../pages/admin/FinancialDashboard"));
const ClientDashboard = async(() => import("../pages/admin/ClientBehaviour"));
const TasksAdmin = async(() => import("../pages/admin/Tasks"));
const HelpDesk = async(() => import("../pages/admin/HelpDesk"));
const ClientsAdmin = async(() => import("../pages/admin/Clients"));
const ClientView = async(() => import("../pages/admin/ClientView"));
const Leads = async(() => import("../pages/admin/Leads"));
const LeadView = async(() => import("../pages/admin/LeadView"));
const TradingAccounts = async(() => import("../pages/admin/TradingAccounts"));
const ApplicationsAdmin = async(() => import("../pages/admin/Applications"));
const Messages = async(() => import("../pages/admin/Messages"));
const ExpiredDocs = async(() => import("../pages/admin/ExpiredDocuments"));

const Transactions = async(() => import("../pages/financial/Transactions"));
const ViewTransaction = async(() => import("../pages/financial/ViewTransaction"));
const InternalTransfers = async(() => import("../pages/financial/InternalTransfers"));
const NewTransaction = async(() => import("../pages/financial/NewTransaction"));
const ViewInternalTransfer = async(() => import("../pages/financial/ViewTransfer"));

const LiveClientsReport = async(() => import("../pages/reporting/LiveClients"));
const LiveAccountsReport = async(() => import("../pages/reporting/LiveAccounts"));
const OpenTradesReport = async(() => import("../pages/reporting/OpenTrades"));

const CommissionBreakdown = async(() => import("../pages/admin/IB/Report/CommissionBreakdown"));
const CPAPayment = async(() => import("../pages/admin/IB/Report/CPAPayment"));
const Debugger = async(() => import("../pages/admin/IB/Report/Debugger"));
const IBReport = async(() => import("../pages/admin/IB/Report/IBReport"));
const RecalculateCommission = async(() => import("../pages/admin/IB/Report/RecalculateCommission"));
const Campaigns = async(() => import("../pages/admin/IB/Campaigns"));
const IBs = async(() => import("../pages/admin/IB/IBs"));
const AdminBanners = async(() => import("../pages/admin/IB/Links/Banners"));
const IBLinks = async(() => import("../pages/admin/IB/Links/IBLinks"));
const LandingPages = async(() => import("../pages/admin/IB/Links/LandingPages"));

const MarketingCampaigns = async(() => import("../pages/admin/Marketing/MarketingCampaigns"));
const MassMail = async(() => import("../pages/admin/Marketing/MassMail"));

const RegistrationReport = async(() => import("../pages/admin/Reports/RegistrationsReport"));
const FinancialReport = async(() => import("../pages/admin/Reports/FinancialReport"));
const ECBRates = async(() => import("../pages/admin/Reports/ECBRates"));
const ScalpersReport = async(() => import("../pages/admin/Reports/ScalpersReport"));
const CustomAccountsReport = async(() => import("../pages/admin/Reports/CustomAccountsReport"));
const CustomClientsReport = async(() => import("../pages/admin/Reports/CustomClientsReport"));
const ResyncReports = async(() => import("../pages/admin/Reports/ResyncReports"));

const User = async(() => import("../pages/admin/userlevel/User"));
const Role = async(() => import("../pages/admin/userlevel/Role"));
const Right = async(() => import("../pages/admin/userlevel/Right"));

const Servers = async(() => import("../pages/configurations/Servers"));
const EditServer = async(() => import("../pages/configurations/EditServer"));


const NotificationsConfig = async(() => import("../pages/configurations/Notifications"));
const PaymentSystems = async(() => import("../pages/configurations/PaymentSystems"));
const CompanyDocuments = async(() => import("../pages/configurations/CompanyDocuments"));
const Translation = async(() => import("../pages/configurations/Translations"));
const Otpauth = async(() => import("../pages/auth/Otpauth"));

// Routes
const landingRoutes = {
  path: "/",
  name: "Profitto Root",
  component: ExamLogin,
  children: null,
  needAuth: false
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboard",
  header: "Trader's Menu",
  icon: SlidersIcon,
  containsHome: true,
  needAuth: true,
  children: [
    {
      path: "/dashboard/default",
      name: "Overview",
      component: Default,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_dashboard']
    }
  ]
};

const admindashboardRoutes = {
  path: "/admin/dashboard",
  name: "Dashboard",
  header: "Admin Sidebar",
  icon: BarChart,
  children: [
    {
      path: "/admin/dashboard/default",
      name: "Dashboard",
      component: AdminDashboard,
    },
    {
      path: "/admin/dashboard/financial",
      name: "Financial",
      component: FinancialDashboard,
    },
    {
      path: "/admin/dashboard/client",
      name: "Client Behaviour",
      component: ClientDashboard,
    }
  ]
};

const taskRoutes = {
  path: "/admin/task",
  name: "Task",
  icon: Briefcase,
  component: TasksAdmin,

};

const helpdeskRoutes = {
  path: "/admin/helpdesk",
  name: "Help Desk",
  icon: HelpCircle,
  component: HelpDesk,

};

const IBReportsRoutes = {
  path: "/admin/ib/report",
  name: "IB - Reports",
  icon: PieChartIcon,
  children: [
    {
      path: "/admin/ib/report/commission-breakdown",
      name: "Commision Breakdown",
      component: CommissionBreakdown
    },
    {
      path: "/admin/ib/report/cpa-payment",
      name: "CPA / CPL Payments",
      component: CPAPayment
    },
    {
      path: "/admin/ib/report/recalculate-commission",
      name: "Recalculate Commissions",
      component: RecalculateCommission
    },
    {
      path: "/admin/ib/report/ibreport",
      name: "IB Report",
      component: IBReport
    },
    {
      path: "/admin/ib/report/debugger",
      name: "Debugger",
      component: Debugger
    }
  ]
};

const IBLinksRoutes = {
  path: "/admin/ib/links",
  name: "IB - Links",
  icon: GitBranch,
  children: [
    {
      path: "/admin/ib/links/ib",
      name: "IB Links",
      component: IBLinks
    },
    {
      path: "/admin/ib/links/banners",
      name: "Banners",
      component: AdminBanners
    },
    {
      path: "/admin/ib/links/landingpages",
      name: "Landing Pages",
      component: LandingPages
    }
  ]
};

const IBRoutes = {
  path: "/admin/ib",
  name: "IB",
  icon: GitBranch,
  children: [
    {
      path: "/admin/ib/ibs",
      name: "IBs",
      component: IBs
    },
    {
      path: "/admin/ib/campaigns",
      name: "Campaigns",
      component: Campaigns
    }
  ]
};

const marketingRoutes = {
  path: "/admin/marketing",
  name: "Marketing",
  icon: Send,
  children: [
    {
      path: "/admin/marketing/campaigns",
      name: "Marketing Campaigns",
      component: MarketingCampaigns
    },
    {
      path: "/admin/marketing/mails",
      name: "Mass Mails",
      component: MassMail
    }
  ]
};

const clientsandleadsRoutes = {
  path: "/admin",
  name: "Clients and Leads",
  icon: UsersIcon,
  children: [
    {
      path: "/admin/clients",
      name: "Clients",
      component: ClientsAdmin
    },
    {
      path: "/admin/leads",
      name: "Leads",
      component: Leads
    },
    /*{
      path: "/admin/leads/view",
      name: "View Lead",
      component: LeadView
    },*/
    {
      path: "/admin/tradingacc",
      name: "Trading Accounts",
      component: TradingAccounts
    },
    {
      path: "/admin/applications",
      name: "Applications",
      component: ApplicationsAdmin,
    },
    {
      path: "/admin/messages",
      name: "Messages",
      component: Messages
    },
    {
      path: "/admin/expireddocs",
      name: "Expired Documents",
      component: ExpiredDocs
    }
  ]
};

const editserverRoutes = {
  path: "/admin/config/servers/:id_mt4/edit",
  name: "Edit Servers",
  component: EditServer,
  needAuth: false
};

const viewLeadRoutes = {
  path: "/admin/leads/view",
  name: "View Lead",
  component: LeadView,
  needAuth: false
};

const viewAccountRoutes = {
  path: "/account/overview/:id_mt4",
  name: "View Account",
  component: ViewAccount,
  needAuth: false
};

const tradingHistoryRoutes = {
  path: "/account/trading/:id_mt4",
  name: "Trading History",
  component: TradingHistory,
  needAuth: false
};

const financialRoutes = {
  path: "/financial",
  name: "Financial",
  icon: DollarSign,
  children: [
    {
      path: "/financial/transactions",
      name: "Transactions",
      component: Transactions
    },
    {
      path: "/financial/transactions/view",
      name: "View Transaction",
      component: ViewTransaction
    },
    {
      path: "/financial/transfers",
      name: "Internal Transfers",
      component: InternalTransfers
    },
    {
      path: "/financial/transfers/view",
      name: "View Internal Transfer",
      component: ViewInternalTransfer
    },
    {
      path: "/financial/new",
      name: "New Transaction",
      component: NewTransaction
    }
  ]
};

const reportingRoutes = {
  path: "/reports",
  name: "Reports - Live",
  icon: File,
  children: [
    {
      path: "/reports/liveclients",
      name: "Live Clients Report",
      component: LiveClientsReport
    },
    {
      path: "/reports/liveaccounts",
      name: "Live Accounts Report",
      component: LiveAccountsReport
    },
    {
      path: "/reports/opentrades",
      name: "Open Trades Report",
      component: OpenTradesReport
    }
  ]
};

const otherReportsRoutes = {
  path: "/reports/other",
  name: "Reports",
  icon: File,
  children: [
    {
      path: "/reports/other/registration",
      name: "Registrations report",
      component: RegistrationReport
    },
    {
      path: "/reports/other/financial",
      name: "Financial Report",
      component: FinancialReport
    },
    {
      path: "/reports/other/ecbrates",
      name: "ECB Rates",
      component: ECBRates
    },
    {
      path: "/reports/other/scalpersreport",
      name: "Scalpers Report",
      component: ScalpersReport
    },
    {
      path: "/reports/other/customacc",
      name: "Custom Accounts Report",
      component: CustomAccountsReport
    },
    {
      path: "/reports/other/customclient",
      name: "Custom Clients Report",
      component: CustomClientsReport
    },
    {
      path: "/reports/other/resync",
      name: "Resync Reports",
      component: ResyncReports
    }
  ]
};

const userlevelRoutes = {
  path: "/admin/userlevel",
  name: "User Level",
  icon: UsersIcon,
  children: [
    {
      path: "/admin/userlevel/user",
      name: "User",
      component: User
    },
    {
      path: "/admin/userlevel/role",
      name: "Role",
      component: Role,
    },
    {
      path: "/admin/userlevel/right",
      name: "Right",
      component: Right
    }
  ]
};

const configRoutes = {
  path: "/admin/config",
  name: "Configuration",
  icon: settingsicon,
  children: [
    {
      path: "/admin/config/servers",
      name: "Servers",
      component: Servers
    },
    {
      path: "/admin/config/notifications",
      name: "Notifications",
      component: NotificationsConfig
    },
    {
      path: "/admin/config/paymentsystem",
      name: "Payment Systems",
      component: PaymentSystems
    },
    {
      path: "/admin/config/companydocs",
      name: "Company Documents",
      component: CompanyDocuments
    },
    {
      path: "/admin/config/translations",
      name: "Translation",
      component: Translation
    }
  ]
};

const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: LayoutIcon,
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile1
    },
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings
    },
    {
      path: "/pages/clients",
      name: "Clients",
      component: Clients
    },
    {
      path: "/pages/projects",
      name: "Projects",
      component: Projects,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/tasks",
      name: "Tasks",
      component: Tasks,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

const authRoutes = {
  path: "/",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "12/24",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
      needAuth: false
    },
    {
      path: "/auth/confirmation",
      name: "Confirmation",
      component: Confirmation,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_dashboard']
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const layoutRoutes = {
  path: "/layouts",
  name: "Layouts",
  icon: MonitorIcon,
  children: [
    {
      path: "/layouts/sidebar-sticky",
      name: "Sticky Sidebar",
      component: SidebarSticky
    },
    {
      path: "/layouts/sidebar-collapsed",
      name: "Sidebar Collapsed",
      component: SidebarCollapsed
    },
    {
      path: "/layouts/boxed",
      name: "Boxed Layout",
      component: Boxed
    },
    {
      path: "/layouts/theme-classic",
      name: "Classic Theme",
      component: ThemeClassic
    },
    {
      path: "/layouts/theme-corporate",
      name: "Corporate Theme",
      component: ThemeCorporate,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/layouts/theme-modern",
      name: "Modern Theme",
      component: ThemeModern,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const documentationRoutes = {
  path: "/docs",
  name: "Documentation",
  icon: BookOpenIcon,
  children: [
    {
      path: "/docs/introduction",
      name: "Introduction",
      component: Introduction
    },
    {
      path: "/docs/installation",
      name: "Getting Started",
      component: Installation
    },
    {
      path: "/docs/plugins",
      name: "Plugins",
      component: Plugins
    },
    {
      path: "/docs/changelog",
      name: "Changelog",
      component: Changelog
    }
  ]
};

const uiRoutes = {
  path: "/ui",
  name: "UI Elements",
  header: "Components",
  icon: GridIcon,
  children: [
    {
      path: "/ui/alerts",
      name: "Alerts",
      component: Alerts
    },
    {
      path: "/ui/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/ui/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/ui/general",
      name: "General",
      component: General
    },
    {
      path: "/ui/grid",
      name: "Grid",
      component: Grid
    },
    {
      path: "/ui/modals",
      name: "Modals",
      component: Modals
    },
    {
      path: "/ui/notifications",
      name: "Notifications",
      component: Notifications
    },
    {
      path: "/ui/tabs",
      name: "Tabs",
      component: Tabs
    },
    {
      path: "/ui/typography",
      name: "Typography",
      component: Typography
    }
  ]
};

const chartRoutes = {
  path: "/charts",
  name: "Charts",
  icon: PieChartIcon,
  badgeColor: "primary",
  badgeText: "New",
  children: [
    {
      path: "/charts/chartjs",
      name: "Chart.js",
      component: Chartjs
    },
    {
      path: "/charts/apexcharts",
      name: "ApexCharts",
      component: ApexCharts,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const formRoutes = {
  path: "/forms",
  name: "Forms",
  icon: CheckSquareIcon,
  children: [
    {
      path: "/forms/layouts",
      name: "Layouts",
      component: Layouts
    },
    {
      path: "/forms/basic-elements",
      name: "Basic Elements",
      component: BasicElements
    },
    {
      path: "/forms/advanced-elements",
      name: "Advanced Elements",
      component: AdvancedElements
    },
    {
      path: "/forms/input-groups",
      name: "Input Groups",
      component: InputGroups
    },
    {
      path: "/forms/editors",
      name: "Editors",
      component: Editors
    },
    {
      path: "/forms/validation",
      name: "Validation",
      component: Validation
    },
    {
      path: "/forms/wizard",
      name: "Wizard",
      component: Wizard
    }
  ]
};

const tableRoutes = {
  path: "/tables",
  name: "Tables",
  icon: ListIcon,
  children: [
    {
      path: "/tables/bootstrap",
      name: "Bootstrap",
      component: BootstrapTables
    },
    {
      path: "/tables/advanced-tables",
      name: "Advanced",
      component: AdvancedTables
    }
  ]
};

const iconRoutes = {
  path: "/icons",
  name: "Icons",
  icon: HeartIcon,
  badgeColor: "info",
  badgeText: "Special",
  children: [
    {
      path: "/icons/feather",
      name: "Feather",
      component: Feather
    },
    {
      path: "/icons/font-awesome",
      name: "Font Awesome",
      component: FontAwesome
    }
  ]
};

const calendarRoutes = {
  path: "/calendar",
  name: "Calendar",
  icon: CalendarIcon,
  component: Calendar,
  badgeColor: "primary",
  badgeText: "New",
  children: null
};

const mapRoutes = {
  path: "/maps",
  name: "Maps",
  icon: MapPinIcon,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ]
};

//menu starts here

const profileRoutes = {
  path: "/profile",
  name: "Profile",
  icon: UsersIcon,
  children: [
    {
      path: "/profile",
      name: "Profile",
      component: Profile1,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_profile']
    },
    {
      path: "/profile/documents",
      name: "Uploaded Documents",
      component: Documents,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_uploadeddocuments']
    },
    {
      path: "/profile/questionnaire",
      name: "Questionnaire",
      component: Questionnaire,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_questionnaire']
    }
    ,
    {
      path: "/profile/agreements",
      name: "My Agreements",
      component: Agreements,
      needAuth: false
    }
  ]
};

const accountRoutes = {
  path: "/account",
  name: "Account",
  icon: UsersIcon,
  children: [
    {
      path: "/account/openaccount",
      name: "Open Account",
      component: OpenAccount,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_openaccount']
    },
    {
      path: "/account/overview",
      name: "Account Overview",
      component: AccountOverview,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_accountoverview']
    }
  ]
};

const fundsRoutes = {
  path: "/funds",
  name: "Funds",
  icon: DollarSign,
  children: [
    {
      path: "/funds/deposit",
      name: "Deposit Funds",
      component: Deposit,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_depositfunds']
    },
    {
      path: "/funds/withdraw",
      name: "Withdraw Funds",
      component: Withdraw,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_withdrawfunds']
    },
    {
      path: "/funds/transfer",
      name: "Transfer Funds",
      component: Transfer,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_transferfunds']
    },
    {
      path: "/funds/transaction",
      name: "Transactions History",
      component: Transaction,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_transactionhistory']
    },
    {
      path: "/funds/paymentdetails",
      name: "Payment Details",
      component: PaymentDetails,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_paymentdetails']
    }
  ]
};

const requestRoutes = {
  path: "/ib",
  name: "Request IB",
  icon: Briefcase,
  component: IB,
  needAuth: true,
  roles: ['admin', 'client'],
  rights: ['R_requestib']
}

const downloadRoutes = {
  path: "/download",
  name: "MT4 Download",
  icon: Download,
  component: Mt4Download,
  needAuth: false
}

const settingsRoutes = {
  path: "/settings",
  name: "Settings",
  icon: settingsicon,
  component: SettingsMain,
  needAuth: true,
  roles: ['admin', 'client'],
  rights: ['R_settings']
}

const IBMenuRoutes = {
  path: "/partner",
  name: "Dashboard",
  header: "IB Menu",
  icon: Activity,
  children: [
    {
      path: "/partner/IBDashboard",
      name: "Dashboard",
      component: IBDashboard,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_ibdashboard']
    },
    {
      path: "/partner/IBTransaction",
      name: "IB Transactions",
      component: IBTransaction,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_ibtransaction']
    },
    {
      path: "/partner/Breakdown",
      name: "Detail Commission Breakdown",
      component: Breakdown,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_commissionbreakdown']
    },
    {
      path: "/partner/withdraw",
      name: "Withdraw",
      component: IBWithdraw,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_ibwithdraw']
    },
    {
      path: "/partner/transfer",
      name: "Transfer To Trading Account",
      component: IBTransfer,
      needAuth: true,
      roles: ['admin', 'client'],
      rights: ['R_transfertradingaccount']
    },
  ]
}

const PerformanceRoutes = {
  path: "/partner/performance-dashboard",
  name: "Performance Dashboard",
  icon: BarChart,
  component: PerformanceDashboard,
  needAuth: true,
  roles: ['admin', 'client'],
  rights: ['R_performancedashboard']
}

const LinksRoutes = {
  path: "/partner/links",
  name: "Links",
  icon: Star,
  component: Links,
  needAuth: true,
  roles: ['admin', 'client'],
  rights: ['R_partnerlinks']
}

const TreeRoutes = {
  path: "/partner/tree",
  name: "Tree",
  icon: Star,
  component: Tree,
  needAuth: true,
  roles: ['admin', 'client'],
  rights: ['R_partnerlinks']
}



const OtherNetworkRoutes = {
  path: "/partner/other-network-commission",
  name: "Other Network Commission",
  icon: GitMerge,
  component: OtherNetworkCommission,
  needAuth: true,
  roles: ['admin', 'client'],
  rights: ['R_othernetworkcommission']
}

const BannersRoutes = {
  path: "/partner/banners",
  name: "Banners",
  icon: Image,
  component: Banners,
  needAuth: true,
  roles: ['admin', 'client'],
  rights: ['R_partnerbanners']
}

// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

const loginRoutes = {
  path: "/login",
  name: "Login",
  component: ExamLogin,
  needAuth: false,
  children: null
};

const otpRoutes = {
  path: "/otpauth",
  name: "OTP Autehntication",
  component: Otpauth,
  needAuth: true,
  children: null,
  roles: ['admin', 'client'],
  rights: ['R_otpauth']
};

const registerRoutes = {
  path: "/register/:code?",
  name: "Register",
  component: SignUp,
  needAuth: false,
  children: null
};

// client Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  pageRoutes,
  layoutRoutes,
  documentationRoutes,
  uiRoutes,
  chartRoutes,
  formRoutes,
  tableRoutes,
  iconRoutes,
  calendarRoutes,
  mapRoutes,
  privateRoutes,
  profileRoutes,
  fundsRoutes,
  settingsRoutes,
  downloadRoutes,
  accountRoutes,
  requestRoutes,
  IBMenuRoutes,
  PerformanceRoutes,
  LinksRoutes,
  TreeRoutes,
  OtherNetworkRoutes,
  BannersRoutes,
  viewAccountRoutes,
  tradingHistoryRoutes,
  viewLeadRoutes
];

// client Dashboard specific routes
export const admindashboard = [
  admindashboardRoutes,
  taskRoutes,
  helpdeskRoutes,
  clientsandleadsRoutes,
  financialRoutes,
  IBReportsRoutes,
  IBLinksRoutes,
  TreeRoutes,
  IBRoutes,
  marketingRoutes,
  reportingRoutes,
  otherReportsRoutes,
  userlevelRoutes,
  configRoutes,
  editserverRoutes
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

export const login = [loginRoutes];

export const register = [registerRoutes];

export const otp = [otpRoutes];

// All routes
export default [
  dashboardRoutes,
  accountRoutes,
  fundsRoutes,
  profileRoutes,
  settingsRoutes,
  downloadRoutes,
  requestRoutes,
  IBMenuRoutes,
  PerformanceRoutes,
  LinksRoutes,
  TreeRoutes,
  OtherNetworkRoutes,
  BannersRoutes,
  //admin sidebar
  admindashboardRoutes,
  taskRoutes,
  helpdeskRoutes,
  clientsandleadsRoutes,
  financialRoutes,
  IBReportsRoutes,
  IBLinksRoutes,
  IBRoutes,
  marketingRoutes,
  reportingRoutes,
  otherReportsRoutes,
  userlevelRoutes,
  configRoutes,
  editserverRoutes
  //pageRoutes,
  //authRoutes,
  //layoutRoutes,
  //documentationRoutes,
  //uiRoutes,
  //chartRoutes,
  //formRoutes,
  //tableRoutes,
  //iconRoutes,
  //calendarRoutes,
  //mapRoutes
];
