import React from "react";
import classnames from "classnames";
import InputMask from "react-input-mask";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";


const Blank = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-1">MT4 Download</h1>
    <h5 className="h5 mb-3">MT4 downloads are available below.</h5>

    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle tag="h5" className="mb-0">Empty card</CardTitle>
          </CardHeader>
          <CardBody>
            
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Blank;
