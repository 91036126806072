import React, { useState } from 'react';

const FormController = (callback) => 
{
	const [inputs, setInputs] = useState({});

	const handleSubmit = (event) =>
	{
		console.log('handleSubmit');

		if(event)
			event.preventDefault();
		
		callback();
	}

	const handleInputChange = (event) =>
	{
		console.log('handleInputChange');
		console.log('event: ', event);
		
		event.persist();	

		if(event.target.type == 'checkbox')	
		{
			console.log('if type checkbox');

			if(event.target.checked == true)
				setInputs(inputs => ({...inputs, [event.target.name]:true}));
			else
				setInputs(inputs => ({...inputs, [event.target.name]:false}));
		}			
		else
		{
			setInputs(inputs => ({...inputs, [event.target.name]:event.target.value}));			
		}	

		console.log('inputs: ', inputs);	
	}

  	return {handleSubmit, handleInputChange, inputs, setInputs}
}

export default FormController;