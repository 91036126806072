import React, { useState } from "react";
import Select from "react-select";
import { Link, Redirect } from "react-router-dom";

import {
  Alert,
  Button, Badge, Container, Row, Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  CustomInput,
  Table,
  Label,
  UncontrolledAlert
} from "reactstrap";

import Statistics from "./Statistics";
import LineChart from "./LineChart";
import Feed from "./Feed";
import Calendar from "./Calendar";
import PieChart from "./PieChart";
import Appointments from "./Appointments";
import Projects from "./Projects";
import BarChart from "./BarChart";

import loginbg from "../../../assets/img/illustrations/chart.png"
import graph from "../../../assets/img/illustrations/graph.png"


import Timeline from "../../../components/Timeline";
import TimelineItem from "../../../components/TimelineItem";
import ApiConfig from "../../../components/ApiConfig";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faPlus,
  faBell
} from "@fortawesome/free-solid-svg-icons";

import jQuery from "jquery";
const $ = jQuery;
window.jQuery = jQuery;

require("smartwizard/dist/js/jquery.smartWizard.min.js");

var apiUrl = ApiConfig().backendURL;

const type = [
  { value: "1", label: "ECN DEMO" },
  { value: "2", label: "STANDARD DEMO" },
  { value: "3", label: "CENTS DEMO" }
];

const leverage = [
  { value: "1", label: "1:1000" },
  { value: "2", label: "1:500" },
  { value: "3", label: "1:400" },
  { value: "4", label: "1:300" },
  { value: "5", label: "1:200" },
  { value: "6", label: "1:100" },
  { value: "7", label: "1:50" },
  { value: "8", label: "1:33" },
  { value: "9", label: "1:10" },
  { value: "10", label: "1:5" },
  { value: "11", label: "1:2" },
  { value: "12", label: "1:1" }
];

const currency = [
  { value: "1", label: "USD" }
];

const initial = [
  { value: "1", label: "1000" },
  { value: "2", label: "3000" },
  { value: "3", label: "5000" },
  { value: "4", label: "10000" },
  { value: "5", label: "50000" },
  { value: "6", label: "100000" }
];

class WizardVariant extends React.Component {
  componentDidMount() {
    $(this.refs.smartwizard)
      .smartWizard({
        theme: this.props.variant, // default OR arrows
        showStepURLhash: false,
        toolbarSettings: {
          toolbarExtraButtons: [
            $(
              '<button class="btn btn-submit btn-primary" type="button">Finish</button>'
            )
          ]
        }
      })
      .on("leaveStep", function(e, anchorObject, stepNumber, stepDirection) {
        if (stepDirection === "forward") {
          // Validation between steps
        }
        return true;
      });

    $(this.refs.smartwizard)
      .find(".btn-submit")
      .on("click", function() {
        alert("Great! The form is ready to submit.");
        // Final validation

        return false;
      });
  }
  render = () => {
    const { color } = this.props;

    return (
      <div ref="smartwizard" className={`wizard wizard-${color} mb-4`}>
        <ul>
          <li>
            <a href={`#step-1`}>
              Open Account
              <br />
              <small>Choose Account</small>
            </a>
          </li>
          <li>
            <a href={`#step-2`}>
              Account Type
              <br />
              <small>Select Account Type</small>
            </a>
          </li>
          <li>
            <a href={`#step-3`}>
              Confirmation
              <br />
              <small>Account Confirmation</small>
            </a>
          </li>
        </ul>

        <div>
          <div id={`step-1`}>
          <FormGroup row>
          <Col sm={12}>
            <div className="custom-controls-stacked">
            <div className="border p-2 mt-1 mb-2">
              <CustomInput
                type="radio"
                id="radio1"
                name="customRadio"
                label="Open Demo Account"
                className="h4"
                defaultChecked
              />
              <small className="text-muted">Select to open demo account</small>
              </div>
              <div className="border p-2 mt-1">
              <small className="text-danger">Please verify your profile to open live account.</small>
              <CustomInput
                type="radio"
                id="radio3"
                label="Open Live Account"
                className="h4"
                disabled
              />
              <small className="text-muted">Select to open live account</small>
              </div>
            </div>
          </Col>
        </FormGroup>
          </div>
          <div id={`step-2`}>
          <FormGroup>
              <Label>Account Type</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={type}
              />
            </FormGroup>
            <FormGroup>
              <Label>Leverage</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue="6"
                options={leverage}
              />
            </FormGroup>
            <FormGroup>
              <Label>Currency</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={currency}
              />
            </FormGroup>
            <FormGroup>
              <Label>Initial Balance</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={initial}
              />
            </FormGroup>
          </div>
          <div id={`step-3`}>
          <h4 className="">Account Confirmation</h4>
          <Table size="sm" className="my-2">
        <tbody>
          <tr>
            <th>Account Type</th>
            <td>ECN DEMO</td>
          </tr>
          <tr>
            <th>Leverage</th>
            <td>1:100</td>
          </tr>
          <tr>
            <th>Currency</th>
            <td>USD</td>
          </tr>
          <tr>
            <th>Balance</th>
            <td>1,000.00 USD</td>
          </tr>
        </tbody>
      </Table>
          </div>
        </div>
      </div>
    );
  };
}

const Default = () => {

  var session = JSON.parse(localStorage.getItem('session'));
  console.log('session: ', session);

  var user = session.user;
  console.log('user: ', user);

  const [emailSent, setEmailSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  const handleResendVerify = () =>
  {
    console.log('handleResendVerify');
    console.log('token: ', session.token);

    fetch(apiUrl + '/resend', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+ session.token
        }        
      })
        .then(res => res.json())
        .then(
          (result) => {
            // alert(JSON.stringify(result));
            if(result.status == 'success')
            {
              setEmailSent(true);
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            alert(JSON.stringify(error));
          }
        )
  }

  const handleCheckVerify = () =>
  {
    fetch(apiUrl + '/user/details', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+ session.token
        }        
      })
        .then(res => res.json())
        .then(
          (result) => {
            // alert(JSON.stringify(result));
            if(result.status == 'success')
            {
              var updatedUser = result.data.user;

              session.user = updatedUser;
              console.log('session.user: ', session.user);

              localStorage.setItem('session', JSON.stringify(session));  

              setEmailVerified(true);
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            alert(JSON.stringify(error));
          }
        )
  }

  console.log('emailVerified: ', emailVerified);
  if(emailVerified)
   return (<Redirect to="/" />)

  if(user.email_verified_at == null)
    return (
      <Container fluid className="p-0">
        <Row>
          <Col lg="6" xl="4" className="">
            <img src={loginbg} width="100%"></img>
          </Col>
          <Col lg="6" xl="8" className="">  

            { 
              emailSent ? 
                <Row>
                  <Col>
                    <UncontrolledAlert
                      color="success"
                      className="alert-outline-coloured"              
                    >
                      <div className="alert-icon">
                        <FontAwesomeIcon icon={faBell} fixedWidth />
                      </div>
                      <div className="alert-message">
                        <p>Verification email had been resent.</p>                    
                      </div>
                    </UncontrolledAlert>
                  </Col>
                </Row> : 
                  null
            }         

            <Row>
              <Col>
                <UncontrolledAlert
                  color="danger"
                  className="alert-outline-coloured"              
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message">
                    <p>Please verify your email first before you can start using this system.</p>
                    <Button color="primary" className="mr-2" onClick={handleCheckVerify}>Check verification</Button>
                    <Button color="secondary" onClick={handleResendVerify}>Resend verification email</Button>
                  </div>
                </UncontrolledAlert>
              </Col>
            </Row>            
          </Col>
        </Row>
      </Container>
      )
  else
      return (
        <Container fluid className="p-0">
          <Row>
            <Col lg="6" xl="4" className="">
              <img src={loginbg} width="100%"></img>
            </Col>
            <Col lg="6" xl="8" className="">
              <div className="mb-3">
                <p className="mb-0">Welcome to Profitto</p>
                <h1 className="h3 mb-0">Verify your profile to start trading!</h1>
              </div>
              <div className="mb-3">
                <p className="mb-0">Follow this simple step to verify your profile</p>
              </div>
              <Timeline className="mb-3">
                <TimelineItem>
                  <strong>ID/Passport</strong>
                  <span className="float-right text-muted text-sm">
                    <Badge color="danger" className="float-right">
                      Not Uploaded
                    </Badge>
                  </span>
                  <p>
                    ID card issued by government or passport in high resolution image
                </p>
                  <p>
                    <Link to="/profile/documents"><Button color="primary" size="sm" outline><FontAwesomeIcon icon={faPlus} /> ID/Passport</Button></Link>
                  </p>
                </TimelineItem>
                <TimelineItem>
                  <strong>Proof of Address</strong>
                  <span className="float-right text-muted text-sm">
                    <Badge color="success" className="float-right">
                      Completed
                  </Badge>
                  </span>
                  <p>
                    Bank statement or utility bill must be clear, full page and under the same registered full name
                </p>
                  <p>
                  <Link to="/profile/documents"><Button color="primary" size="sm" outline><FontAwesomeIcon icon={faPlus} /> Proof of Address</Button></Link>
                  </p>
                </TimelineItem>
                <TimelineItem>
                  <strong>Questionnaire</strong>
                  <span className="float-right text-muted text-sm">
                    <Badge color="danger" className="float-right">
                      Not Uploaded
                    </Badge>
                  </span>
                  <p >

                  </p>
                  <p>
                  <Link to="/profile/questionnaire"><Button color="primary" size="sm" outline><FontAwesomeIcon icon={faPlus} /> Questionnaire</Button></Link>
                  </p>
                </TimelineItem>
              </Timeline>
            </Col>

          </Row>
          <Row>
            <Col lg="12" className="mt-5">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5" className="">Open Account</CardTitle>
                  <h6 className="card-subtitle text-muted">
                    Open your demo or live trading account
                  </h6>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="6" xl="6" className="">
                      <img src={graph} width="100%"/>
                    </Col>
                    <Col lg="6" xl="6" className="">
                    <WizardVariant variant="default" color="primary" />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      )
}

export default Default;
