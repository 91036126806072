import React, { useState } from "react";
import classnames from "classnames";
import InputMask from "react-input-mask";
import Select from "react-select";
import { Link, Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faPlus,
  faFile,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";

import ApiConfig from "../../components/ApiConfig";

const apiUrl = ApiConfig().backendURL;
const session = JSON.parse(localStorage.getItem('session'));

const empstatus = [
  { value: "1", label: "Employed", name: "cd_employment_status" },
  { value: "2", label: "Self Employed", name: "cd_employment_status" },
  { value: "3", label: "Retired", name: "cd_employment_status" },
  { value: "4", label: "Student", name: "cd_employment_status" },
  { value: "5", label: "Unemployed", name: "cd_employment_status" }
];

const fund = [
  { value: "1", label: "Employement", name: "cd_fund_source" },
  { value: "2", label: "Inheritance", name: "cd_fund_source" },
  { value: "3", label: "Investments", name: "cd_fund_source" },
  { value: "4", label: "Savings", name: "cd_fund_source" },
  { value: "5", label: "Others", name: "cd_fund_source" }
];

const education = [
  { value: "1", label: "Secondary", name: "cd_education_level" },
  { value: "2", label: "High School", name: "cd_education_level" },
  { value: "3", label: "Bachelors Degree", name: "cd_education_level" },
  { value: "4", label: "Masters Degree", name: "cd_education_level" },
  { value: "5", label: "Doctorate", name: "cd_education_level" },
  { value: "6", label: "None of the above", name: "cd_education_level" }
];
const annualincome = [
  { value: "1", label: "< 50,000", name: "cd_annual_income" },
  { value: "2", label: "50,000 - 100,000", name: "cd_annual_income" },
  { value: "3", label: "> 100,000", name: "cd_annual_income" }
];

const networth = [
  { value: "1", label: "Less than 0", name:"cd_net_worth" },
  { value: "2", label: "0 - 25,000", name:"cd_net_worth" },
  { value: "3", label: "25,000 - 75,000", name:"cd_net_worth" },
  { value: "4", label: "75,000 - 150,000", name:"cd_net_worth" },
  { value: "5", label: "Over 150,000", name:"cd_net_worth" }
];


const investment = [
  { value: "1", label: "100 - 25,000", name: "cd_investment_amount" },
  { value: "2", label: "25,000 - 50,000", name: "cd_investment_amount" },
  { value: "3", label: "50,000 - 100,000", name: "cd_investment_amount" },
  { value: "4", label: "Over 100,000", name: "cd_investment_amount" }
];
const purpose = [
  { value: "1", label: "Hedging", name: "cd_trading_purpose" },
  { value: "2", label: "Investment", name: "cd_trading_purpose" },
  { value: "3", label: "Speculative", name: "cd_trading_purpose" },
  { value: "4", label: "Diversification", name: "cd_trading_purpose" },
  { value: "5", label: "Risk Management", name: "cd_trading_purpose" }
];
const period = [
  { value: "1", label: "Up to 6 Months", name: "cd_investment_period" },
  { value: "2", label: "6 Months to 1 Year", name: "cd_investment_period" },
  { value: "3", label: "1 - 3 Years", name: "cd_investment_period" },
  { value: "4", label: "3 - 5 Years", name: "cd_investment_period" },
  { value: "5", label: "Over than 5 Years", name: "cd_investment_period" }
];
const understand = [
  { value: "1", label: "Yes", name: "cd_leverage_understanding" },
  { value: "2", label: "No", name: "cd_leverage_understanding" }
];
const risk = [
  { value: "1", label: "Yes", name: "cd_forex_understanding" },
  { value: "2", label: "No", name: "cd_forex_understanding" }
];

const inputObj = {  
  cd_employment_status: null,
  cd_fund_source: null,
  cd_education_level: null,
  cd_annual_income: null,
  cd_net_worth: null,
  cd_investment_amount: null,
  cd_trading_purpose: null,
  cd_investment_period: null,
  cd_leverage_understanding: null,
  cd_forex_understanding: null  
}

const Questionnaire = () => 
{  
  const [submitSuccess, setSubmitSuccess] = useState(false);
  console.log('submitSuccess: ', submitSuccess);  

  const [api, setApi] = useState({
    status: 'before',
    data: {
      cd_employment_status: '',
      cd_fund_source: '',
      cd_education_level: '',
      cd_annual_income: '',
      cd_net_worth: '',
      cd_investment_amount: '',
      cd_trading_purpose: '',
      cd_investment_period: '',
      cd_leverage_understanding: '',
      cd_forex_understanding: '',
      cd_questionnaire_status: '' 
    }
  });

  console.log('api: ', api);

  const handleChange = (event) =>
  {
    console.log('handleChange');
    console.log('event: ', event);
    console.log('event target name: ', event.target.name);
    console.log('event target value: ', event.target.value);

    var prevApiData = api.data;
    var newApiData = {...prevApiData, [event.target.name]: event.target.value};

    setApi({
      ...api, data: newApiData
    });
  }

  const handleSubmit = () =>
  {    
    console.log('handleSubmit');        

    var formData = new FormData();

    formData.set('cd_employment_status', api.data.cd_employment_status);
    console.log('fd cd_employment_status: ', formData.get('cd_employment_status'));

    formData.set('cd_fund_source', api.data.cd_fund_source);
    console.log('fd cd_fund_source: ', formData.get('cd_fund_source'));

    formData.set('cd_education_level', api.data.cd_education_level);
    console.log('fd cd_education_level: ', formData.get('cd_education_level'));

    formData.set('cd_annual_income', api.data.cd_annual_income);
    console.log('fd cd_annual_income: ', formData.get('cd_annual_income'));

    formData.set('cd_net_worth', api.data.cd_net_worth);
    console.log('fd cd_net_worth: ', formData.get('cd_net_worth'));

    formData.set('cd_investment_amount', api.data.cd_investment_amount);
    console.log('fd cd_investment_amount: ', formData.get('cd_investment_amount'));

    formData.set('cd_trading_purpose', api.data.cd_trading_purpose);
    console.log('fd cd_trading_purpose: ', formData.get('cd_trading_purpose'));

    formData.set('cd_investment_period', api.data.cd_investment_period);
    console.log('fd cd_investment_period: ', formData.get('cd_investment_period'));

    formData.set('cd_leverage_understanding', api.data.cd_leverage_understanding);
    console.log('fd cd_leverage_understanding: ', formData.get('cd_leverage_understanding'));

    formData.set('cd_forex_understanding', api.data.cd_forex_understanding);
    console.log('fd cd_forex_understanding: ', formData.get('cd_forex_understanding'));

    console.log('token: ', session.token);
    console.log('apiUrl: ', apiUrl);

    fetch(apiUrl + '/user_questionnaire/update', {
      method: 'POST',      
      body: formData,
      headers: {
        'Authorization': 'Bearer '+ session.token
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          // alert(JSON.stringify(result));
          if(result.status == 'success')
          {   
            var uq = result.data.user_questionnaire;

            alert("Your answers had been submitted successfully.");
            setApi({
              status: 'before',
              data: api.data
            });
          }
          else
          {
            alert(result.msg);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert(JSON.stringify(error));
        }
      )
  }

  const getUserQuestionnaire = () =>
  {
    console.log('getUserQuestionnaire');

    fetch(apiUrl + '/user_questionnaire/get', {
      method: 'GET',            
      headers: {
        'Authorization': 'Bearer '+ session.token
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          // alert(JSON.stringify(result));
          if(result.status == 'success')
          {                         
            setApi({
              status: 'after',
              data: {
                cd_employment_status: result.data.user_questionnaire.cd_employment_status,
                cd_fund_source: result.data.user_questionnaire.cd_fund_source,
                cd_education_level: result.data.user_questionnaire.cd_education_level,
                cd_annual_income: result.data.user_questionnaire.cd_annual_income,
                cd_net_worth: result.data.user_questionnaire.cd_net_worth,
                cd_investment_amount: result.data.user_questionnaire.cd_investment_amount,
                cd_trading_purpose: result.data.user_questionnaire.cd_trading_purpose,
                cd_investment_period: result.data.user_questionnaire.cd_investment_period,
                cd_leverage_understanding: result.data.user_questionnaire.cd_leverage_understanding,
                cd_forex_understanding: result.data.user_questionnaire.cd_forex_understanding,
                cd_questionnaire_status: result.data.user_questionnaire.cd_questionnaire_status
              }
            });
          }
          else
          {
            alert(result.msg);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert(JSON.stringify(error));
        }
      )
  }  

  if(api.status == 'before')  
    getUserQuestionnaire();  

  if(submitSuccess)
    return (<Redirect to="/profile" />)
  else
    return (
      <Container fluid className="p-0" >
        <h1 className="h3 mb-3"><FontAwesomeIcon icon={faQuestion} /> Questionnaire</h1>                
        
        {
          api.data.cd_questionnaire_status == 'A' ? 
            <Row>
              <Col>
                <p>Your questionnaire had been approved. You are no longer able to change your answers.</p>
              </Col>
            </Row> : 
              null
        }

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0">1. Personal Details</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Employment Status</Label>
                  <Input
                    type="select"
                    name="cd_employment_status"                                        
                    onChange={handleChange} 
                    value={api.data.cd_employment_status}                   
                    disabled={api.data.cd_questionnaire_status == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_employment_status">Employed</option>
                    <option value="2" name="cd_employment_status">Self Employed</option>
                    <option value="3" name="cd_employment_status">Retired</option>
                    <option value="4" name="cd_employment_status">Student</option>
                    <option value="5" name="cd_employment_status">Unemployed</option>                    
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Source of Fund</Label>
                  <Input
                    type="select"
                    name="cd_fund_source"                                      
                    onChange={handleChange}
                    value={api.data.cd_fund_source}
                    disabled={api.data.cd_fund_source == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_fund_source">Employement</option>
                    <option value="2" name="cd_fund_source">Inheritance</option>
                    <option value="3" name="cd_fund_source">Investments</option>
                    <option value="4" name="cd_fund_source">Savings</option>
                    <option value="5" name="cd_fund_source">Others</option>                    
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Level of Education</Label>                  
                  <Input
                    type="select"
                    name="cd_education_level"                                      
                    onChange={handleChange}
                    value={api.data.cd_education_level}
                    disabled={api.data.cd_education_level == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_education_level">Secondary</option>
                    <option value="2" name="cd_education_level">High School</option>
                    <option value="3" name="cd_education_level">Bachelors Degree</option>
                    <option value="4" name="cd_education_level">Masters Degree</option>
                    <option value="5" name="cd_education_level">Doctorate</option>   
                    <option value="6" name="cd_education_level">None of the above</option>                                   
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label>Estimated Annual income (USD)</Label>                  
                  <Input
                    type="select"
                    name="cd_annual_income"                                      
                    onChange={handleChange}
                    value={api.data.cd_annual_income}
                    disabled={api.data.cd_annual_income == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_annual_income">{"< 50,000"}</option>
                    <option value="2" name="cd_annual_income">{"50,000 - 100,000"}</option>
                    <option value="3" name="cd_annual_income">{"> 100,000"}</option>                                                  
                  </Input>                  
                </FormGroup>
                <FormGroup>
                  <Label>Estimated Net Worth (USD)</Label>                  

                  <Input
                    type="select"
                    name="cd_net_worth"                                      
                    onChange={handleChange}
                    value={api.data.cd_net_worth}
                    disabled={api.data.cd_net_worth == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_net_worth">{"Less than 0"}</option>
                    <option value="2" name="cd_net_worth">{"0 - 25,000"}</option>
                    <option value="3" name="cd_net_worth">{"25,000 - 75,000"}</option>
                    <option value="4" name="cd_net_worth">{"75,000 - 150,000"}</option>
                    <option value="5" name="cd_net_worth">{"Over 150,000"}</option>                                                  
                  </Input>                                    

                </FormGroup>
                <FormGroup>
                  <Label>Estimated Investment Amount (USD)</Label>                  

                  <Input
                    type="select"
                    name="cd_investment_amount"                                      
                    onChange={handleChange}
                    value={api.data.cd_investment_amount}
                    disabled={api.data.cd_investment_amount == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_investment_amount">{"100 - 25,000"}</option>
                    <option value="2" name="cd_investment_amount">{"25,000 - 50,000"}</option>
                    <option value="3" name="cd_investment_amount">{"50,000 - 100,000"}</option>
                    <option value="4" name="cd_investment_amount">{"Over 100,000"}</option>                    
                  </Input>                                      

                </FormGroup>
                <FormGroup>
                  <Label>Intended Purpose of Trading</Label>                  

                  <Input
                    type="select"
                    name="cd_trading_purpose"                                      
                    onChange={handleChange}
                    value={api.data.cd_trading_purpose}
                    disabled={api.data.cd_trading_purpose == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_trading_purpose">Hedging</option>
                    <option value="2" name="cd_trading_purpose">Investment</option>
                    <option value="3" name="cd_trading_purpose">Speculative</option>
                    <option value="4" name="cd_trading_purpose">Diversification</option>    
                    <option value="5" name="cd_trading_purpose">Risk Management</option>                    
                  </Input>                     

                </FormGroup>
                <FormGroup>
                  <Label>Period of Time You are Willing to Maintain Funds for investment purposes</Label>                  

                  <Input
                    type="select"
                    name="cd_investment_period"                                      
                    onChange={handleChange}
                    value={api.data.cd_investment_period}
                    disabled={api.data.cd_investment_period == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_investment_period">Up to 6 Months</option>
                    <option value="2" name="cd_investment_period">6 Months to 1 Year</option>
                    <option value="3" name="cd_investment_period">1 - 3 Years</option>
                    <option value="4" name="cd_investment_period">3 - 5 Years</option>    
                    <option value="5" name="cd_investment_period">Over than 5 Years</option>                    
                  </Input>                                      

                </FormGroup>
                <FormGroup>
                  <Label>Do you understand that trading high leverage products, may result in losing all your portfolio’s available funds?</Label>                 

                  <Input
                    type="select"
                    name="cd_leverage_understanding"                                      
                    onChange={handleChange}
                    value={api.data.cd_leverage_understanding}
                    disabled={api.data.cd_leverage_understanding == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_leverage_understanding">Yes</option>
                    <option value="2" name="cd_leverage_understanding">No</option>                                    
                  </Input>                      

                </FormGroup>
                <FormGroup>
                  <Label>Do you have the knowledge in Forex and understand the risk of this investment? (Trading on margin products involves a high level of risk)</Label>                  

                  <Input
                    type="select"
                    name="cd_forex_understanding"                                      
                    onChange={handleChange}
                    value={api.data.cd_forex_understanding}
                    disabled={api.data.cd_forex_understanding == 'A' ? true : false}
                  >
                    <option value="">Select...</option>
                    <option value="1" name="cd_forex_understanding">Yes</option>
                    <option value="2" name="cd_forex_understanding">No</option>                                    
                  </Input> 

                </FormGroup>
                <hr />
                  <Button color="primary" onClick={handleSubmit} >
                    Submit
                  </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
}

export default Questionnaire;
