import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Jumbotron,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import { User } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch, faArrowLeft, faHourglass, faClock, faCheck, faMousePointer, faPencilAlt
} from "@fortawesome/free-solid-svg-icons";

import avatar from "../../assets/img/avatars/avatar.jpg";

import FormController from '../../components/FormController';
import ApiConfig from '../../components/ApiConfig';

import { useTranslation } from 'react-i18next';
import i18n from '../../components/i18n';

var apiUrl = ApiConfig().backendURL;

const SignIn = () => {  

  const[loginSuccess, setLoginSuccess] = useState(false);
  const[toOtp, setToOtp] = useState(false);
  const { t, i18n } = useTranslation();
  console.log('i18n: ', i18n);
  // i18n.changeLanguage('en');

  const loginApi = () =>
  {
    console.log('loginApi');
    console.log('inputs: ', inputs);

    var formData = new FormData();

    formData.set('email', inputs.email);
    formData.set('password', inputs.password);

    fetch(apiUrl + '/user/login', {
      method: 'POST',      
      body: formData
    })
      .then(res => res.json())
      .then(
        (result) => {
          // alert(JSON.stringify(result));
          if(result.status == 'success')
          {
            if(result.data.user_preferences.cd_two_factor == 'Y')
            {
              var userSession = {
                user: result.data.user,
                user_profiles: result.data.user_profiles,
                roles: result.data.role,
                rights: ['R_otpauth'],
                token: result.data.token
              };

              localStorage.setItem('session', JSON.stringify(userSession));              
              setToOtp(true);
            }              
            else
            {
              var userSession = {
                user: result.data.user,
                user_profiles: result.data.user_profiles,
                roles: result.data.role,
                rights: result.data.rights,
                token: result.data.token
              };

              localStorage.setItem('session', JSON.stringify(userSession));              
              setLoginSuccess(true);
            }              
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert(JSON.stringify(error));
        }
      )
  }

  const {inputs, handleInputChange, handleSubmit} = FormController(loginApi);

  console.log('loginSuccess: ', loginSuccess);

  if(toOtp)
    return(<Redirect to="/otpauth" />)

  if(loginSuccess)
    return (<Redirect to='/dashboard/default' />)
  else
    return (
      <React.Fragment>
        <Container className="p-0">
          <Row>
            <Col lg="6" className="mb-4">
            <a href="/" className="h3 text-primary"><FontAwesomeIcon icon={faArrowLeft} className="mr-1" /> Return to Profittoltd.com</a>
            </Col>
          </Row>
          <Row>
            <Col lg="10" className="d-flex">
              <Card className="flex-fill w-100">
                <CardBody>
                  <div className="mb-3">
                    <p className="mb-0">Please login to proceed. New client? <a href="/register">Sign Up</a> now!</p>
                    <h1 className="h3 mb-0">Welcome to Profitto Client Area</h1>
                  </div>
                  <hr />
                  <div className="m-sm-4">
                    <h2>{t('login.label')}</h2>
                    <Form>
                      <FormGroup>
                      <Label>Email</Label>
                        <Input
                          bsSize="lg"
                          type="text"
                          name="email"
                          placeholder="Email"
                          onChange={handleInputChange}
                          value={inputs.email || ''}
                        />
                        <Label>Password</Label>
                        <Input
                          bsSize="lg"
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={handleInputChange}
                          value={inputs.password || ''}
                        />
                        <small>
                          <Link to="#" id={"help"}>Forgot my password</Link>
                        </small>
                        <UncontrolledTooltip
                          placement="top"
                          target={"help"}
                        >
                          Dapatkan Kata Laluan sebelum memulakan ujian.
                        </UncontrolledTooltip>
                      </FormGroup>
                      <div className="mt-3">                      
                        <Button color="primary" size="lg" block onClick={handleSubmit}>
                          Log In
                        </Button>                      
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </React.Fragment>
    )
};

export default SignIn;
